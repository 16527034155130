import {
  faArrowRight,
  faDownload,
  faPlay
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackgroundImage from "gatsby-background-image";
import getYouTubeID from "get-youtube-id";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import YouTube from "react-youtube";
import { useLocation } from '@reach/router';

import { getFilteredSearchLink } from "../helper/link";
import CookiesAreNecessary from "./cookiesAreNecessary";
import Label from "./label";
import cookiesContext from "../context/cookies/cookiesContext";

const TeaserCard = ({
  title,
  image,
  content,
  tags,
  createdAt,
  landscape,
  className,
  articleType,
  link,
  file,
  video,
  big,
  isOverview,
  readMoreLabelText,
  downloadLabelText,
  richTextOrigin,
  backgroundImage
}) => {
  const { cookies } = useContext(cookiesContext);
  const marketingCookies = cookies[2];
  const [showVideo, setShowVideo] = React.useState(false);
  const location = useLocation();
  const footer = (
    <div className="teaser-card-footer">
      {link && (
        <a href={link} className="teaser-card-footer-link">
          {readMoreLabelText}
          <FontAwesomeIcon
            icon={faArrowRight}
            size="lg"
            className="teaser-card-footer-icon"
          />
        </a>
      )}
      {file && (
        <a
          href={file}
          rel="noreferrer"
          target="_blank"
          className="teaser-card-footer-link"
        >
          {downloadLabelText}
          <FontAwesomeIcon
            icon={faDownload}
            size="lg"
            className="teaser-card-footer-icon"
          />
        </a>
      )}
    </div>
  );

  return !marketingCookies && video ? (
    <CookiesAreNecessary necessaryCookies={"marketingCookies"} />
  ) : (
    <article
      className={`teaser-card ${className} ${landscape ? "landscape" : ""} ${
        big ? "big" : ""
      } ${video ? "video" : ""}`}
    >
      <Container className="p-0">
        <Row noGutters={!big}>
          <Col
            xs={12}
            sm={12}
            md={landscape ? (big ? 8 : isOverview ? 3 : 6) : 12}
            lg={landscape ? (big ? 8 : isOverview ? 3 : 4) : 12}
            xl={landscape ? (big ? 8 : isOverview ? 3 : 4) : 12}
          >
            {richTextOrigin ? (
              <div
                className={`teaser-card-header`}
                style={{backgroundImage: `url(${backgroundImage})`, backgroundColor: 'transparent'}}
              >
                {video && (
                  <>
                    <div
                      role="button"
                      tabIndex={0}
                      className={"teaser-card-header-play-button"}
                      onKeyDown={() => {
                        setShowVideo(true);
                      }}
                      onClick={() => {
                        setShowVideo(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlay}
                        size="lg"
                        className="teaser-card-header-play-button-icon"
                      />
                    </div>
                    {showVideo ? (
                      <YouTube
                        className={"teaser-card-youtube-player"}
                        opts={{
                          playerVars: {
                            autoplay: 1,
                          },
                        }}
                        videoId={getYouTubeID(video)}
                      />
                    ) : null}
                  </>
                )}
              </div>
            ) : (
              image ? (
                <BackgroundImage
                  className={`teaser-card-header`}
                  fluid={image}
                  Tag="div"
                >
                  {video && (
                    <>
                      <div
                        role="button"
                        tabIndex={0}
                        className={"teaser-card-header-play-button"}
                        onKeyDown={() => {
                          setShowVideo(true);
                        }}
                        onClick={() => {
                          setShowVideo(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlay}
                          size="lg"
                          className="teaser-card-header-play-button-icon"
                        />
                      </div>
                      {showVideo ? (
                        <YouTube
                          className={"teaser-card-youtube-player"}
                          opts={{
                            playerVars: {
                              autoplay: 1,
                            },
                          }}
                          videoId={getYouTubeID(video)}
                        />
                      ) : null}
                    </>
                  )}
                  {articleType && (
                    <div className={"teaser-card-header-label"}>
                      {articleType}
                    </div>
                  )}
                </BackgroundImage>
              ) : (
                <div className={"teaser-card-header no-image"}>
                  {articleType && (
                    <div className={"teaser-card-header-label"}>
                      {articleType}
                    </div>
                  )}
                </div>
              )
            )}
            
          </Col>

          <Col
            className="d-flex align-items-center"
            xs={12}
            sm={12}
            md={landscape ? (big ? 4 : isOverview ? 9 : 6) : 12}
            lg={landscape ? (big ? 4 : isOverview ? 9 : 8) : 12}
            xl={landscape ? (big ? 4 : isOverview ? 9 : 8) : 12}
          >
            <div className="teaser-card-content">
              {tags.length ? (
                <div className="tags">
                  {tags.map(tag => (
                    <Label
                      key={tag.contentful_id}
                      text={tag.name}
                      type="secondary"
                      className="tag"
                      link={getFilteredSearchLink(
                        tag.contentful_id,
                        tag.node_locale
                      )}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}
              {createdAt && (
                <div className="teaser-card-topic">{createdAt}</div>
              )}
              {title && <h1 className={`teaser-card-title ${location.pathname.includes("/past-event/") ? "twoLinesTitle" : ""}`}>{title}</h1>}
              {content && <p className="teaser-card-text">{content}</p>}
              {footer}
            </div>
          </Col>
        </Row>
      </Container>
      {footer}
    </article>
  );
};

/* PropTypes */
TeaserCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
  content: PropTypes.string,
  tags: PropTypes.array,
  createdAt: PropTypes.string,
  landscape: PropTypes.bool,
  big: PropTypes.bool,
  articleType: PropTypes.string,
  file: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  video: PropTypes.string,
  overviewPage: PropTypes.bool,
};

TeaserCard.defaultProps = {
  className: "",
  title: "",
  image: null,
  content: "",
  tags: [],
  createdAt: "",
  landscape: false,
  big: false,
  articleType: "",
  file: "",
  link: "",
  video: null,
  overviewPage: false,
};

export default TeaserCard;
