import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { combine } from "../helper/classNames";
import { getValue } from "../helper/translation";
import cookiesContext from "../context/cookies/cookiesContext";
import MicrocopyContext from "../context/microcopy/microcopyContext";

const CookiesAreNecessary = ({ necessaryCookies, className }) => {
  const { cookiesSettedTo } = useContext(cookiesContext);
  const microcopy = useContext(MicrocopyContext);
  const text = `${necessaryCookies &&
    getValue(`cookie.${necessaryCookies}`, microcopy)}
  ${getValue("cookie.cookieNecessary", microcopy)}`;

  const handleCookies = () => {
    cookiesSettedTo(false);
  };
  return (
    <article
      className={combine(
        "teaser-card",
        "cookies-warning-message",
        className && className
      )}
    >
      <div>
        <ReactMarkdown>{text}</ReactMarkdown>
        <div>
          {getValue("cookie.changeConsent", microcopy)}
          <span className={"cookies-link"} onClick={handleCookies}>
            {getValue("cookie.here", microcopy)}
          </span>
        </div>
      </div>
    </article>
  );
};

export default CookiesAreNecessary;
