/* Global imports */
import PropTypes from "prop-types";
import React from "react";

/* Local imports */

/* Component definition */
const Label = ({ className, text, type, link, onClick, ...rest }) => {
  return link ? (
    <a
      href={link}
      className={`label label--${type} label--link ${className}`}
      {...rest}
    >
      {text}
    </a>
  ) : (
    <span
      className={`label label--${type} ${
        onClick ? "label--filter" : ""
      } ${className}`}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      {...rest}
    >
      {text}
    </span>
  );
};
/* PropTypes */
Label.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]),
  link: PropTypes.string,
  onClick: PropTypes.func,
};
Label.defaultProps = {
  className: "",
  type: "primary",
};

/* Local utility functions */

/* Styles */

export default Label;
